import React, { useEffect, useState } from 'react';
import { db } from '../../store/firebase.config';
import { collection, getDocs } from 'firebase/firestore';
import { Select, MenuItem, Button, Modal, Box, Typography, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import CloseIcon from '@mui/icons-material/Close'; // Close icon from Material UI
import 'swiper/swiper-bundle.css';

const FacebookPosts = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [pageNames, setPageNames] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPageName, setSelectedPageName] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activePost, setActivePost] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const firebasePosts = await getPostsFromFirebase();
      setPosts(firebasePosts);
      setFilteredPosts(firebasePosts);
      setLocations(getUniqueValues(firebasePosts, 'location'));
      setPageNames(getUniqueValues(firebasePosts, 'pageName'));
      setLoading(false);
    };

    fetchPosts();
  }, []);

  const getPostsFromFirebase = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'facebookPosts'));
      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        const media = data.media || [];
        const images = media.filter(item => item.type === 'image').map(item => item.url) || [];

        return {
          id: data.id,
          message: data.message || '',
          images: images,
          tags: data.tags || [],
          created_time: data.created_time,
          location: data.location?.city || 'Unknown',
          pageName: data.pageName || 'Unknown',
        };
      });
    } catch (error) {
      console.error('Error fetching posts from Firebase:', error);
      return [];
    }
  };

  const getUniqueValues = (posts, field) => {
    return [...new Set(posts.map(post => post[field]).filter(Boolean))];
  };

  const handlePostClick = (post) => {
    setActivePost(post);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setActivePost(null);
  };

  return (
    <div className="container">
      {/* Filter Buttons */}
      <div className="flex flex-wrap justify-center gap-4 pb-4">
        <Button
          variant="contained"
          sx={{
            backgroundColor: selectedTag === 'dog' ? 'blue' : 'default',
            color: selectedTag === 'dog' ? 'white' : 'default',
            '&:hover': { backgroundColor: selectedTag === 'dog' ? 'darkblue' : 'default' }
          }}
          onClick={() => setFilteredPosts(posts.filter(post => post.tags.includes('dog')))}
        >
          Dogs
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: selectedTag === 'cat' ? 'green' : 'default',
            color: selectedTag === 'cat' ? 'white' : 'default',
            '&:hover': { backgroundColor: selectedTag === 'cat' ? 'darkgreen' : 'default' }
          }}
          onClick={() => setFilteredPosts(posts.filter(post => post.tags.includes('cat')))}
        >
          Cats
        </Button>
        {/* <Select
          displayEmpty
          value={selectedLocation}
          onChange={(e) => setFilteredPosts(posts.filter(post => post.location === e.target.value))}
          style={{ minWidth: 200 }}
        >
          <MenuItem disabled hidden value="">Location</MenuItem>
          {locations.map((location, index) => (
            <MenuItem key={index} value={location}>{location}</MenuItem>
          ))}
        </Select> */}
        {/* <Select
          displayEmpty
          value={selectedPageName}
          onChange={(e) => setFilteredPosts(posts.filter(post => post.pageName === e.target.value))}
          style={{ minWidth: 200 }}
        >
          <MenuItem disabled value="">Shelter Name</MenuItem>
          {pageNames.map((name, index) => (
            <MenuItem key={index} value={name}>{name}</MenuItem>
          ))}
        </Select> */}
        <Button variant="outlined" onClick={() => setFilteredPosts(posts)}>Reset</Button>
      </div>
      {/* Posts Section */}
      <ul className="p-4 flex flex-wrap gap-4 pt-5 justify-center lg:justify-start">
        {filteredPosts.map((post) => (
          <li
            key={post.id}
            className="hover:shadow-xl shadow-2xl rounded-md w-72 relative duration-25 ease-in-out cursor-pointer"
            onClick={() => handlePostClick(post)}
          >
            <div
              className="flex flex-col w-full rounded-t"
              style={{
                height: '300px',
                backgroundImage: `url(${post.images[0]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                overflow: 'hidden',
              }}
            ></div>
            <div className="flex flex-col w-full absolute bottom-0">
              <div className="p-2 bg-white block w-full">
                <p className="mb-0 text-md text-black">
                  {post.message.slice(0, 30)}{post.message.length > 30 && '...'}
                </p>
                {/* <p className="text-xs text-gray-500">Location: {post.location}</p>
                <p className="text-xs text-gray-500">Page: {post.pageName}</p> */}
              </div>
            </div>
          </li>
        ))}
      </ul>
      {loading && <p>Loading posts...</p>}
      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '40vh',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
              xs: '80%',    // 100% width on extra small screens (mobile)
              sm: '400px',     // 80% width on small screens
            },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
            position: 'relative', // to position the close button correctly
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: -10,
              right: -35,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>

          {activePost && (
            <>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                navigation
                modules={[Navigation]}
              >
                {activePost.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        height: '300px',
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '4px',
                      }}
                    ></div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Typography
                sx={{
                  mt: 2,
                  maxHeight: '100px',
                  overflowX: 'hidden',
                  position: 'relative',
                  paddingBottom: '20px', // Give space for gradient overlay
                  '&::-webkit-scrollbar': {
                    width: '8px', // Width of scrollbar
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark thumb for visibility
                    borderRadius: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                  },
                }}
              >
                {activePost.message}
                {activePost.message.length > 100 && (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      height: '20px',
                      background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
                      pointerEvents: 'none',
                    }}
                  ></div>
                )}
              </Typography>
              <div className='text-center'>
                <a className='mt-4 inline-block border-b-2 border-solid pb-2 hover:border-white' target="_blank" rel="noopener" href={`https://www.facebook.com/${activePost.id}`}>Find Out More</a>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default FacebookPosts;
