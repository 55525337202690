import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div className='container p-4 md:p-4 lg:p-0'>
      <p>If you have any questions or issues, please feel free to contact me at <a href="mailto:contact@ahyslop.co.uk">contact@ahyslop.co.uk</a></p>
    </div>
  );
};

export default ContactUs;
