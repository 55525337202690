export default function Terms() {
    return (
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
          
          <p className="mb-4">
            Welcome to <strong>PawPages</strong>. By using our app, you agree to comply with and be bound by the following terms and conditions. Please read them carefully. If you do not agree to these terms, you may not use the app.
          </p>
    
          <h2 className="text-2xl font-semibold mb-3">1. Acceptance of Terms</h2>
          <p className="mb-4">
            By accessing or using PawPages, you agree to these Terms and Conditions and our Privacy Policy. These terms apply to all visitors, users, and others who access or use the app.
          </p>
    
          <h2 className="text-2xl font-semibold mb-3">2. Use of the App</h2>
          <p className="mb-4">
            You agree to use PawPages only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use of the app. Prohibited behavior includes, but is not limited to:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Posting offensive or misleading content.</li>
            <li>Harassing or causing distress to other users.</li>
            <li>Attempting to hack or disrupt the app's services.</li>
          </ul>
    
          <h2 className="text-2xl font-semibold mb-3">3. User-Generated Content</h2>
          <p className="mb-4">
            Users may post content such as comments, images, and other material. You are solely responsible for the content you post, and by posting, you warrant that:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>You own or control all rights to the content.</li>
            <li>Your content does not violate any third-party rights, including copyright or privacy rights.</li>
            <li>Your content does not contain harmful, misleading, or illegal material.</li>
          </ul>
    
          <h2 className="text-2xl font-semibold mb-3">4. Account Security</h2>
          <p className="mb-4">
            You are responsible for maintaining the confidentiality of your account credentials. PawPages will not be liable for any loss or damage arising from unauthorized use of your account.
          </p>
    
          <h2 className="text-2xl font-semibold mb-3">5. Limitation of Liability</h2>
          <p className="mb-4">
            PawPages is provided "as is" without any warranties, express or implied. We do not guarantee that the app will be error-free or available at all times. We will not be liable for any loss or damage resulting from your use of the app.
          </p>
    
          <h2 className="text-2xl font-semibold mb-3">6. Changes to Terms</h2>
          <p className="mb-4">
            We reserve the right to update these Terms and Conditions at any time. Any changes will be effective immediately upon posting to the app. Your continued use of the app constitutes acceptance of the revised terms.
          </p>
    
          <h2 className="text-2xl font-semibold mb-3">7. Contact Information</h2>
          <p className="mb-4">
            If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:contact@ahyslop.co.uk" className="text-blue-500">contact@ahyslop.co.uk</a>.
          </p>
    
          <p className="mt-6">
            By using PawPages, you acknowledge that you have read and understood these terms and agree to be bound by them.
          </p>
        </div>
      );
}