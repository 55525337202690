import React from 'react';
import { Typography, Box, Container, Paper } from '@mui/material';

const AboutUs = () => {
  return (
    <div className='container p-4 md:p-4 lg:p-0'>
        <h1 className='title'>How It Works</h1>
        <Typography variant="body1" paragraph>
          Welcome to our platform! Here, we automatically pull posts from the Facebook page 
          <a
            href="https://www.facebook.com/rescuedpetdirectory"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold' }}
          >
            {' '}RescuedPet
          </a>. This platform was created to help rescue organizations publicize their animals and 
          make it easier for users like you to find the perfect pet.
        </Typography>
        <Typography variant="body1" paragraph>
          If you're looking for a pet, this is your one-stop place to discover pets from various 
          organizations and locations. Browse through posts, find the pet that suits you, and 
          connect with rescue groups to bring your new furry friend home.
        </Typography>
        <Typography variant="body1" paragraph>
          Are you looking to advertise an animal? It's simple! All you need to do is share your post 
          on the Facebook page
          <a
            href="https://www.facebook.com/rescuedpetdirectory"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold' }}
          >
            {' '}RescuedPet
          </a>. The post will automatically be uploaded here for others to see.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom sx={{ mt: 3 }}>
          Why This Platform Exists
        </Typography>
        <Typography variant="body1">
          This project was inspired by a desire to help rescue organizations reach a larger audience 
          and make it easier for potential pet owners to find animals in need of loving homes. By 
          connecting people with rescues, we aim to give more animals the chance they deserve.
        </Typography>
    </div>
  );
};

export default AboutUs;
