import './App.scss';
import NavBar from './navigation/nav-bar.component';
import Footer from './navigation/footer.component.js';
import LandingPage from './components/landing-page.component.js';
import ContactUs from './components/page-sections/contact/contact-page.component.js';
import FacebookRedirect from './components/facebookPosts/facebook-redirect.js';
import AdminPage from './components/admin-page.component.js';
import Terms from './components/page-sections/terms/terms-page.component.js'
import AboutUs from './components/page-sections/about-us.component.js';
import { Box } from '@mui/system';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import '@fortawesome/fontawesome-svg-core/styles.css'; 
import { config } from '@fortawesome/fontawesome-svg-core';

function LoadingScreen() {
  return (
    <Box>
      <NavBar />
      <div className='loading-container'>
        <div className="loading-spinner"></div>
      </div>
    </Box>
  );
}

function ProtectedRoute({ children }) {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [passwordPrompt, setPasswordPrompt] = useState(true);

  const checkPassword = (password) => {
    const adminPassword = "salty-slug";
    return password === adminPassword;
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const password = e.target.elements.password.value;

    if (checkPassword(password)) {
      setIsAuthorized(true);
      setPasswordPrompt(false);
    } else {
      alert("Incorrect password!");
    }
  };

  if (!isAuthorized && passwordPrompt) {
    return (
      <div className="password-prompt-container">
        <form className='p-4 flex flex-col gap-4 justify-center items-center pt-52' onSubmit={handlePasswordSubmit}>
          <input
            className='border-2 border-solid border-grey p-2 max-w-52'
            type="password"
            name="password"
            placeholder="Enter admin password"
            required
          />
          <button className='button' type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return isAuthorized ? children : null;
}

function App() {
  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    const logOut = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthed(true);
      } else {
        setAuthed(false);
      }
      setLoading(false);
    });

    return () => {
      logOut();
    };
  }, []);

  // Render loading screen while loading authentication
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <NavBar /> 
      <div className='App container mx-auto py-8 flex flex-col min-h-screen' component="main">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="" element={<LandingPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/redirect" element={<FacebookRedirect />} />

          {/* Protected Admin Route */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Outlet></Outlet>
      </div>
      <Footer />
    </div>
  );
}

export default App;
