import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { db } from '../../store/firebase.config';
import { doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

const RedirectHandler = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      console.log('Authorization code:', code);

      const fetchAccessTokenAndStorePosts = async () => {
        setIsLoading(true);
        try {
          const tokenExchangeUrl = `https://graph.facebook.com/v12.0/oauth/access_token?client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${encodeURIComponent('https://rescued.pet/redirect')}&client_secret=${process.env.REACT_APP_FACEBOOK_APP_SECRET}&code=${code}`;
          const tokenResponse = await axios.get(tokenExchangeUrl);
          const { access_token: userAccessToken } = tokenResponse.data;

          if (!userAccessToken) {
            console.error('Failed to obtain user access token.');
            setIsLoading(false);
            return;
          }

          const pageResponse = await axios.get(
            `https://graph.facebook.com/v12.0/me/accounts?fields=id,name,access_token,location&access_token=${encodeURIComponent(userAccessToken)}`
          );

          const pages = pageResponse.data.data;
          if (!pages || pages.length === 0) {
            console.error('No pages found for this user.');
            setIsLoading(false);
            return;
          }

          const page = pages[0]; // Assuming you want the first page
          const { access_token: pageAccessToken, id: pageId, location, name: pageName } = page;

          if (!location) {
            console.warn('No location data found for the page.');
          }

          const postsResponse = await axios.get(
            `https://graph.facebook.com/${encodeURIComponent(pageId)}/posts?fields=id&access_token=${encodeURIComponent(pageAccessToken)}`
          );

          const posts = postsResponse.data.data;
          if (!posts || posts.length === 0) {
            console.log('No posts found for the page.');
            setIsLoading(false);
            return;
          }

          let processedCount = 0;
          for (const post of posts) {
            await processPost(post, pageAccessToken, pageName, location);
            processedCount++;
            setLoadingProgress(Math.round((processedCount / posts.length) * 100));
          }

          console.log('All posts processed successfully.');
          setIsLoading(false);
        } catch (error) {
          console.error('Error during token exchange or fetching posts:', error.message);
          setIsLoading(false);
        }
      };

      const processPost = async (post, accessToken, pageName, location) => {
        try {
          const fullPostUrl = `https://graph.facebook.com/${post.id}?fields=message,created_time,id,attachments{media,type,subattachments}&access_token=${encodeURIComponent(accessToken)}`;
          const fullPostResponse = await axios.get(fullPostUrl);
          const fullPost = fullPostResponse.data;

          const attachments = fullPost.attachments?.data[0];
          let mediaUrls = [];
          if (attachments?.subattachments) {
            mediaUrls = attachments.subattachments.data.map((subAttachment) => {
              if (subAttachment.media?.image?.src) {
                return { type: 'image', url: subAttachment.media.image.src };
              }
              if (subAttachment.media?.source) {
                return { type: 'video', url: subAttachment.media.source };
              }
              return null;
            }).filter(Boolean);
          } else if (attachments) {
            if (attachments.media?.image?.src) {
              mediaUrls.push({ type: 'image', url: attachments.media.image.src });
            }
            if (attachments.media?.source) {
              mediaUrls.push({ type: 'video', url: attachments.media.source });
            }
          }

          if (mediaUrls.length === 0) {
            console.log(`Skipping post ${post.id} as it has no valid media.`);
            return;
          }

          const postWithTagsAndLocation = {
            ...fullPost,
            pageName,
            media: mediaUrls,
            location: location || null,
          };

          const postRef = doc(db, 'facebookPosts', post.id);
          await setDoc(postRef, postWithTagsAndLocation, { merge: true });
          console.log(`Post ${post.id} processed successfully.`);
        } catch (error) {
          console.error(`Error processing post ${post.id}:`, error.message || error);
        }
      };

      fetchAccessTokenAndStorePosts();
    } else {
      console.error('No authorization code found in the URL');
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>
          <p>Downloading posts: {loadingProgress}%</p>
          <div style={{ width: '100%', backgroundColor: '#ccc', borderRadius: '4px' }}>
            <div
              style={{
                width: `${loadingProgress}%`,
                height: '10px',
                backgroundColor: '#4caf50',
                borderRadius: '4px',
              }}
            ></div>
          </div>
        </div>
      ) : loadingProgress === 100 ? (
        <div>
          <p>Download Complete!</p>
          <div style={{ width: '100%', backgroundColor: '#ccc', borderRadius: '4px' }}>
            <div
              style={{
                width: '100%',
                height: '10px',
                backgroundColor: '#4caf50',
                borderRadius: '4px',
              }}
            ></div>
          </div>
        </div>
      ) : null}
    </div>
  );
  
};

// Function to extract tags from description
const extractTagsFromDescription = (message) => {
  const tags = [];
  const keywords = {
    temperament: ['nervous', 'friendly', 'shy', 'playful', 'calm', 'aggressive'],
    size: ['small', 'medium', 'large'],
    specialNeeds: ['blind', 'deaf', 'special diet', 'medication'],
  };

  for (const category in keywords) {
    keywords[category].forEach(keyword => {
      if (message.toLowerCase().includes(keyword)) {
        tags.push(keyword);
      }
    });
  }

  return tags;
};

// Function to extract age from the description
const extractAgeFromDescription = (message) => {
  const ageRegex = /\b(\d+)\s*(year|yr|years?)\b/i;
  const match = message.match(ageRegex);
  return match ? parseInt(match[1], 10) : null;
};

export default RedirectHandler;
