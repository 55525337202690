import React from "react";
import FacebookLogin from'./facebookPosts/facebook-login-button.component';

export default function LandingPage(props) {
  return ( 
    <>
      <FacebookLogin />
    </>
  );
}  
  